<template>
<div>
    <file-pond ref="pond" class-name="my-pond" labelFileProcessing="Cargando Archivo.." labelFileProcessingComplete="Archivo Cargado" labelButtonRemoveItem="Eliminar" labelTapToCance=""  labelTapToUndo="" labelIdle="Arrastrá los archivos o <span style='color:#52b69a ' class='filepond--label-action-mio filepond--label-action'>Seleccioná</span>" labelFileTypeNotAllowed="El formato del archivo no es válido" :allow-multiple="this.getMultiple()" fileValidateTypeLabelExpectedTypes="Acepta {allButLastType} o {lastType}" :server="serverFilePond" />
    <input type="hidden" :id="this.id" :name="field.codigo" :class="'send-data-register_'+id_estado" />
    <input type="hidden" :id="this.id+'_file_upload'" :name="field.codigo" value="" :class="'file-upload-tmp-'+id_estado" />

</div>
</template>

<style>
.alto-remove-icon-multiple {
    font-size: 13px !important;
    color: #fefefe;
    padding-bottom: 1px;
}

.btn-remove-multiple {
    border: none !important;
    border-radius: 71px !important;
    width: 26px !important;
    background-color: #1b4b31;
}

.card-multiple {
    background-color: #f1f0ef !important;
    box-shadow: none !important;
}

.card-body-multiple {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: #369763;
    border-radius: 5px;
    color: white;
}

.text-file-multiple {
    font-size: 0.75em;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #e4f1ea;
    width: 100%;
}

.filepond--credits {
    display: none !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import $ from 'jquery'
import Vue from 'vue'

import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
//props
import S3Config from '../../../../../props.config'


// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

const s3 = new AWS.S3({
    accessKeyId: S3Config.VUE_APP_ACCESS_KEY_ID,
    secretAccessKey: S3Config.VUE_APP_SECRET_ACCESS_KEY,
    region: S3Config.VUE_APP_REGION
});

export default {
    mixins: [],
    components: {},
    mounted: function () {
        
    },
    data: function () {
        let storeAux = this.$store;
        let objAtributo = this.getDataAtributo();
        return {
            serverFilePond: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    //obtengo el valor del input
                    let valorInput = $("#"+objAtributo.id+"_file_upload").val();
                    //seteo el valor concatenado ;1
                   $("#"+objAtributo.id+"_file_upload").val(valorInput + ";1");
                    s3.upload({
                        Bucket: S3Config.VUE_APP_BUCKET,
                        Key: objAtributo.idTramite + '_' + Math.floor(Math.random() * 10001) + '_' + Date.now() + '_' + Math.floor(Math.random() * 10001) + '.' + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name,
                        Body: file,
                        ContentType: file.type,
                        ACL: 'private',
                    }, { partSize: 50 * 1024 * 1024, queueSize: 1 }, function (err, data) {
                        if (err) {
                            //obtengo el valor del input
                            valorInput = $("#"+objAtributo.id+"_file_upload").val();
                            //seteo el valor concatenado ;1
                            $("#"+objAtributo.id+"_file_upload").val(valorInput.replace(";1",""));
                            error('No se pudo cargar el archivo');
                            return;
                        }
                        load(data.Key);
                        objAtributo.dataKey = data.Key;
                        objAtributo.fileName = file.name;
                        storeAux.dispatch("tramiteGenerico/sendDataFile", objAtributo);
                        //elimino ;1 del input
                         //obtengo el valor del input
                        valorInput = $("#"+objAtributo.id+"_file_upload").val();
                        //seteo el valor concatenado ;1
                        $("#"+objAtributo.id+"_file_upload").val(valorInput.replace(";1",""));
                    });
                },
                revert: function (key) {
                    storeAux.dispatch("tramiteGenerico/removeFile", key);
                },
            }
        }
    },
    methods: {
        getLabelFilePond() {
            if (this.type.toUpperCase().trim() == 'ARCHIVOMULTIPLE') {
                return "Arrastre los archivos o <span style='color:#52b69a ' class='filepond--label-action-mio filepond--label-action'>Seleccione</span>";
            } else {
                return "Arrastre el archivos o <span style='color:#52b69a ' class='filepond--label-action-mio filepond--label-action'>Seleccione</span>";
            }
        },
        getMultiple() {
            if (this.type.toUpperCase().trim() == 'ARCHIVOMULTIPLE') {
                return true;
            } else {
                return false;
            }
        },
        getvalueFiles(valor) {
            if (valor != null) {
                return valor;
            }
            return 0;
        },
        getValidateClass(obligatorio) {
            return "send-no-validate-field";
        },

        getDataAtributo() {
            let obj = new Object;
            obj.idTramite = this.id_tramite;
            obj.codigoAtributo = this.field.codigo;
            obj.randomNum = Math.floor(Math.random() * 100) + 1;
            obj.randomNum_b = Math.floor(Math.random() * 100) + 1;
            obj.id = this.id;
            return obj;
        }
    },
    computed: {
    },
    props: {
        field: Object,
        id_estado: String | Number,
        codigo_estado: String,
        type: String,
        id: String,
        id_tramite:String | Number,
    }
}
</script>>
